import { deepClone } from '../../tools/mergeInto';
import { getType } from '../../tools/utils/typeUtils';
import { sanitize } from '../../tools/serialisation/sanitize';
import { Observable } from '../../tools/observable';
import { display } from '../../tools/display';
function ensureProperties(context, propertiesConfig, name) {
  const newContext = {
    ...context
  };
  for (const [key, {
    required,
    type
  }] of Object.entries(propertiesConfig)) {
    /**
     * Ensure specified properties are strings as defined here:
     * https://docs.datadoghq.com/logs/log_configuration/attributes_naming_convention/#user-related-attributes
     */
    if (type === 'string' && key in newContext) {
      /* eslint-disable @typescript-eslint/no-base-to-string */
      newContext[key] = String(newContext[key]);
    }
    if (required && !(key in context)) {
      display.warn(`The property ${key} of ${name} context is required; context will not be sent to the intake.`);
    }
  }
  return newContext;
}
export function createContextManager(name = '', {
  customerDataTracker,
  propertiesConfig = {}
} = {}) {
  let context = {};
  const changeObservable = new Observable();
  const contextManager = {
    getContext: () => deepClone(context),
    setContext: newContext => {
      if (getType(newContext) === 'object') {
        context = sanitize(ensureProperties(newContext, propertiesConfig, name));
        customerDataTracker === null || customerDataTracker === void 0 ? void 0 : customerDataTracker.updateCustomerData(context);
      } else {
        contextManager.clearContext();
      }
      changeObservable.notify();
    },
    setContextProperty: (key, property) => {
      context[key] = sanitize(ensureProperties({
        [key]: property
      }, propertiesConfig, name)[key]);
      customerDataTracker === null || customerDataTracker === void 0 ? void 0 : customerDataTracker.updateCustomerData(context);
      changeObservable.notify();
    },
    removeContextProperty: key => {
      delete context[key];
      customerDataTracker === null || customerDataTracker === void 0 ? void 0 : customerDataTracker.updateCustomerData(context);
      ensureProperties(context, propertiesConfig, name);
      changeObservable.notify();
    },
    clearContext: () => {
      context = {};
      customerDataTracker === null || customerDataTracker === void 0 ? void 0 : customerDataTracker.resetCustomerData();
      changeObservable.notify();
    },
    changeObservable
  };
  return contextManager;
}
