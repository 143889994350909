import { display } from '../../tools/display';
import { getType } from '../../tools/utils/typeUtils';
/**
 * Simple check to ensure user is valid
 */
export function checkUser(newUser) {
  const isValid = getType(newUser) === 'object';
  if (!isValid) {
    display.error('Unsupported user:', newUser);
  }
  return isValid;
}
