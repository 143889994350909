/**
 * LIMITATION:
 * For NPM setup, this feature flag singleton is shared between RUM and Logs product.
 * This means that an experimental flag set on the RUM product will be set on the Logs product.
 * So keep in mind that in certain configurations, your experimental feature flag may affect other products.
 *
 * FORMAT:
 * All feature flags should be snake_cased
 */
// We want to use a real enum (i.e. not a const enum) here, to be able to check whether an arbitrary
// string is an expected feature flag
import { objectHasValue } from './utils/objectUtils';
// eslint-disable-next-line no-restricted-syntax
export var ExperimentalFeature;
(function (ExperimentalFeature) {
  ExperimentalFeature["WRITABLE_RESOURCE_GRAPHQL"] = "writable_resource_graphql";
  ExperimentalFeature["CONSISTENT_TRACE_SAMPLING"] = "consistent_trace_sampling";
  ExperimentalFeature["MISSING_URL_CONTEXT_TELEMETRY"] = "missing_url_context_telemetry";
})(ExperimentalFeature || (ExperimentalFeature = {}));
const enabledExperimentalFeatures = new Set();
export function initFeatureFlags(enableExperimentalFeatures) {
  if (Array.isArray(enableExperimentalFeatures)) {
    addExperimentalFeatures(enableExperimentalFeatures.filter(flag => objectHasValue(ExperimentalFeature, flag)));
  }
}
export function addExperimentalFeatures(enabledFeatures) {
  enabledFeatures.forEach(flag => {
    enabledExperimentalFeatures.add(flag);
  });
}
export function isExperimentalFeatureEnabled(featureName) {
  return enabledExperimentalFeatures.has(featureName);
}
export function resetExperimentalFeatures() {
  enabledExperimentalFeatures.clear();
}
export function getExperimentalFeatures() {
  return enabledExperimentalFeatures;
}
